import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import fontawsm from './assets/all.min.js'
import VeeValidate from 'vee-validate';
import setupInterceptors from "./services/setupInterceptors";

Vue.config.productionTip = false

Vue.use(VeeValidate);
Vue.use(fontawsm);

setupInterceptors(store);

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
