import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import UserService from '../services/user.service';
import EventBus from "../common/EventBus";

import WalletDeposit from '../views/WalletDepositView.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },

  {
    path: "/signup",
    name: "signup",
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/SignUpView.vue");
    },
  },

  {
    path: "/signin",
    name: "signin",
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/SignInView.vue");
    },
  },

  {
    path: "/games",
    name: "games",
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/GamesView.vue");
    },
  },

  {
    path: "/leaderboards",
    name: "leaderboards",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/LeaderboardsView.vue"
      );
    },
  },

  {
    path: "/profile",
    name: "profile",
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/ProfileView.vue");
    },
  },

  {
    path: "/wallet",
    name: "wallet",
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/WalletView.vue");
    },
  },

  {
    path: "/wallet/deposit",
    name: "deposit",
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/WalletDepositView.vue");
    },
  },

  {
    path: "/game/flappy_bird",
    name: "flappyBird",
    component: function () {
      return import(/* webpackChunkName: "about" */ "../games/FlappyBird.vue");
    },
  },

  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //   }
  // }
];

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ["/", "/signin", "/signup"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired) {
    UserService.getUserBoard().then(response => { 
      if (response.status === 200) {
        next();
      }
    }, error => {
        if (error.response && (error.response.status === 403 || error.response.status === 401 || error.response.status === 500)) {
          EventBus.dispatch("logout");
        }
      }
    );
  } else {
    next();
  }
});

export default router
