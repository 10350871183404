<template>
  <div id="app">
    <div class="logo" @click="pushToHome" v-if="!inGame">
      <img alt="GMESTOP Logo" src="@/assets/logo.png">
    </div>

    <div class="content">
      <router-view/>
    </div>

    <nav class="nav" v-if="currentUser && !inGame">
      <router-link class="nav__item" to="/">
        <i class="fas fa-home"></i>
      </router-link>

      <router-link class="nav__item" to="/games">
        <i class="fas fa-gamepad"></i>
      </router-link>

      <router-link class="nav__item" to="/leaderboards">
        <i class="fas fa-chart"></i>
      </router-link>

      <router-link class="nav__item" to="/profile">
        <i class="fas fa-user"></i>
      </router-link>

      <router-link class="nav__item" to="/wallet">
        <i class="fas fa-wallet"></i>
      </router-link>
    </nav>
  </div>
</template>

<script>
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i
.test(navigator.userAgent)) {
} else {
  window.location = "https://google.com"
}

import EventBus from "./common/EventBus";

export default {
  data() {
    return {
      inGame: false,
      access: true
    }
  },

  methods: {
    pushToHome: function() {
      if (this.$route.path != "/") {
        this.$router.push("/")
      }
    },

    logOut() {
      this.inGame = false;

      this.$store.dispatch('auth/logout');

      if (this.$route.path != "/signin") {
        this.$router.push("/signin")
      } else {
        this.$router.push("/");
      }
    }
  },

  mounted() {
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  watch: {
    $route: function() {
      this.inGame = this.$route.path.includes("/game/");
    }
  },

  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
  },
  beforeDestroy() {
    EventBus.remove("logout");
  },
}
</script>

<style lang="scss">
*, *::before, *::after {
  box-sizing: border-box;
}

html, body {
  margin: 0;
}

#app {
  width: 100vw;
  height: 100svh;
  padding: 40px 0;
  background: #020202;
  font-family: 'Outfit';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  overflow: hidden !important;
}

.logo {
  height: 5%;
  margin: 20px 0;
}

.content {
  height: calc(90% - 40px);
  padding: 20px;
}

.nav {
  width: 100%;
  height: 8%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  gap: 25px;
  align-content: center;
  align-items: center;
  background: #212121;
  z-index: 10 !important;

  &__item {
    font-size: 32px;
    color: #fff !important;

    &.router-link-exact-active {
      color: #F4212C !important;
    }
  }
}

@media (max-height: 700px) {
  .nav__item {
    font-size: 24px;
  }
}

.dark-bg {
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, .92);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9;
	}
</style>
