import api from "./api";
import TokenService from "./token.service";

class AuthService {
  login({ username, password }) {
    return api
      .post("/auth/signin", {
        username,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          TokenService.setUser(response.data);
        }

        return response.data;
      });
  }

  logout() {
    TokenService.removeUser();
  }

  register({ username, email, password }) {
    return api.post("/auth/signup", {
      username,
      email,
      password,
    });
  }

  changeProfileIcon(newProfileIcon) {
    return api
      .post("/auth/changeProfileIcon", {
        newProfileIcon
      })
      .then((response) => {
        if (response.data.newProfileIcon) {
          TokenService.setUserProfileIcon(response.data.newProfileIcon);
        }

        return response.data;
      });
  }

  changeEmail(oldEmail, newEmail) {
    return api
      .post("/auth/changeEmail", {
        oldEmail,
        newEmail
      })
      .then((response) => {
        if (response.data.newEmail) {
          TokenService.setUserEmail(response.data.newEmail);
        }

        return response.data;
      });
  }

  changePassword(oldPassword, newPassword) {
    return api
      .post("/auth/changePassword", {
        oldPassword,
        newPassword,
      })
      .then((response) => {
        return response.data;
      });
  }

  playGame(gameId) {
    return api
      .post("/game/playGame", {
        gameId
      })
      .then((response) => {
        return response.data;
      })
  }

  createHighScore(gameId, score) {
    return api
      .post("/game/createHighScore", {
        gameId,
        score
      })
      .then((response) => {
        return response.data;
      });
  }
}

export default new AuthService();
