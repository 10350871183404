<template>
	<div class="modal">
		<form name="form" @submit.prevent="handleSubmit" class="modal__form" v-if="!displayQR">
			<h1>Deposit amount</h1>

			<div class="form-group">
				<input v-model="coinAmount" v-validate="'required|min:1'" type="number" name='coin-amount' placeholder="Amount of coins to deposit">
				<div v-if="errors.has('coin-amount')" class="error">Field empty or invalid!</div>
			</div>

			<div class="form-group">
				<div class="error" v-if="message">{{ message }}</div>
			</div>

			<div class="form-group">
				<button class="btn" type="submit">Confirm</button>
				<button class="btn" type="button" @click="goBack">Cancel</button>
			</div>
		</form>

		<div class="qr-code" v-if="displayQR">
			<h1>Scan this QR to pay</h1>
			<qrcode-vue :value="qrValue" :size="qrSize" level="H" />

			<h1>or click <br><a :href="qrValue" target="_blank">HERE</a></h1>
		</div>
	</div>
</template>

<script>
	import QrcodeVue from 'qrcode.vue';
	import api from '../services/api';

	export default {
		name: 'DepositModal',

		components: {
			QrcodeVue
		},

		data() {
			return {
				coinAmount: 0,
				loading: false,
				message: '',
				qrValue: '',
				qrSize: 300,
				displayQR: false
			};
		},

		methods: {
			goBack() {
				this.$router.push("/wallet");
			},

			showAlert: (message) => {
				alert(message);
			},

			handleSubmit() {
				if (this.loading) return;

				this.loading = true;
				this.$validator.validateAll().then(isValid => {
					if (!isValid) {
						this.loading = false;
						return;
					}
				});

				if (this.coinAmount) {
					api.post("/transaction/coinsTransfer", {amount: this.coinAmount})
						.then((transaction) => {
							const transactionData = transaction.data;
							this.displayQR = true;
							this.qrValue = transactionData.qrCodeURL;
							console.log(transactionData);
							console.log(this.qrValue);

							// transactionData.transactionId;
							// transactionData.transactionReference;
						})
						.catch((err) => {
							console.error(">>", err);
						});

					// this.$store.dispatch('auth/changePassword', {oldPassword: this.oldPassword, newPassword: this.newPassword}).then(() => {
					// 	this.$emit("close");
					// },
					// error => {
					// 	console.log(error);
					// 	this.loading = false;
					// 	this.message = (error.response && error.response.data && error.response.data.message) ||
					// 	error.message ||
					// 	error.toString();
					// });
				}
			}
		},
	}
</script>

<style lang="scss" scoped>
	.modal {
		width: 90vw;
		// min-height: 50vh;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 40px 20px;
		border-radius: 15px;
		z-index: 10;

		&__form {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 40px;

			input {
				width: 95%;
				padding: 10px 20px;
				background: none;
				border: 2px solid #f4212c;
				color: #fff !important;
				font-size: 20px;
				font-family: 'Outfit';
				font-weight: 300;

				&::placeholder {
					color: #f5f5f5;
				}
			}

			.error {
				color: #f4212c;
				text-align: left;
				font-size: 24px;
				margin: 10px;
			}
		}
	}

	.btn {
		width: 90%;
		padding: 10px 30px;
		border-radius: 20px;
		background: #f4212c;
		font-size: 24px;
		font-weight: bold;
		text-transform: uppercase;
		text-decoration: none !important;
		color: #fff !important;
		border: 0;
		margin-top: 30px;

		&:hover {
			cursor: pointer;
		}
	}

	.sign-in {
		width: 100%;
		height: 70%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&__header {
			font-size: 32px;
			letter-spacing: 5px;
			font-weight: bold;
			text-transform: uppercase;
			margin-bottom: 60px;
		}
	}
</style>