import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },

    refreshToken({ commit }, accessToken) {
      commit("refreshToken", accessToken);
    },

    changeProfileIcon({ commit }, newIconURL) {
      return AuthService.changeProfileIcon(newIconURL).then(
        (response) => {
          commit("changeProfileIcon", response.newProfileIcon);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    changeEmail({ commit }, emailData) {
      let oldEmail = emailData.oldEmail;
      let newEmail = emailData.newEmail;

      return AuthService.changeEmail(oldEmail, newEmail).then(
        (response) => {
          commit("changeEmail", response.email);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    changePassword({ commit }, passwordData) {
      let oldPassword = passwordData.oldPassword;
      let newPassword = passwordData.newPassword;

      return AuthService.changePassword(oldPassword, newPassword).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    playGame({ commit }, gameID) {
      return AuthService.playGame(gameID).then(
        (response) => {
          commit("changeCoinBalance", response.coins);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    createHighScore({ commit }, data) {
      return AuthService.createHighScore(data.gameId, data.score).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    // AUTHENTICATION
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    refreshToken(state, accessToken) {
      state.status.loggedIn = true;
      state.user = { ...state.user, accessToken: accessToken };
    },

    // USER PROFILE
    changeProfileIcon(state, newIconURL) {
      state.user.profileIcon = newIconURL;
    },
    changeEmail(state, newEmail) {
      state.user.email = newEmail;
    },

    // WALLET
    changeCoinBalance(state, balance) {
      state.user.coins = balance;
    }
  },
};
