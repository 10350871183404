<template>
  <div class="home">
    <div class="header">
      <div class="header__text">Modern</div>
      <div class="header__icon"><i class="fas fa-joystick"></i></div>
      <div class="header__text">Arcade</div>
      <div class="header__icon"><i class="fas fa-gamepad"></i></div>
      <div class="header__text">Experience</div>
    </div>

    <div class="description">
      <div class="description__header">Fueled by</div>
      <div class="description__header description__header--accent">$GME</div>
      <div class="description__text">The movement coin designed to unite people on the Solana blockchain</div>
    </div>

    <div class="auth" v-if="!currentUser">
      <router-link class="auth__button" to="/signup">Sign Up</router-link>
      <router-link class="auth__button" to="/signin">Sign In</router-link>
    </div>

     <!-- <div class="auth">
      <div class="auth__button" @click="createTransfer">Create transfer</div>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import api from '../services/api';

export default {
  name: 'HomeView',
  data() {
    return {
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    createTransfer() {
      api.post("/transaction/coinsTransfer").then((response) => {
        console.log(response);
        // api.post("/transaction/transferValidation", {
        //   transactionId: response.data.transactionId,
        //   transactionReference: response.data.transactionReference
        // })
        // .then((response) => {
        //   console.log(response);
        // });
      });
    }
  },
  mounted() {
    // console.log(this.flappy);
  }
}
</script>

<style lang="scss" scoped>
  .home {
    width: 100%;
    height: 100%;
  }
  
  .header {
    width: 100%;
    height: 45%;

    &__text {
      font-size: 30px;
      letter-spacing: 10px;
    }

    &__icon {
      font-size: 36px;
      color: #f4212c;
    }
  }

  .description {
    width: 80%;
    height: 40%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 5px;

    &__header {
      font-size: 30px;
      font-weight: 700;
      text-transform: uppercase;

      &--accent {
        font-size: 64px;
        color: #f4212c;
      }
    }

    &__text {
      font-size: 20px;
    }
  }

  .auth {
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    &__button {
      width: 60%;
      padding: 10px 30px;
      border-radius: 20px;
      background: #f4212c;
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
      color: #fff !important;
      text-decoration: none !important;
    }
  }

  @media (max-height: 700px) {
    .header {
      &__text {
        font-size: 28px;
      }

      &__icon {
        font-size: 30px;
      }
    }

    .description {
      gap: 5px;

      &__header {
        font-size: 24px;

        &--accent {
          font-size: 32px;
        }
      }

      &__text {
        font-size: 18px;
      }
    }
  }
</style>
